/*
	const imprintColorDropdown = new imprintColorDropdown4i();
	imprintColorDropdown.Init(common);
*/

const imprintColorDropdown4i = function(){
	let predictiveText = "";
	let common, prodConfig;
	
	let init = (commonJS) => {
		common = commonJS;
		prodConfig = common.ProductConfig;
		
		var colorSections = [].slice.call(document.getElementsByClassName("imprint-color-section"));
		colorSections.forEach(function (el) {
			if (el.querySelector(".input_active")) {
				el.querySelector(".input_active").addEventListener("mousedown", function (e) {
					bindActiveInput(e, el);
				});
			}
			if (el.querySelector(".prodColorOptions") && el.querySelector(".prodColorOptions").querySelectorAll(".spnColorChip")) {
				var selectColorOptions = [].slice.call(el.querySelector(".prodColorOptions").querySelectorAll(".spnColorChip"));
				selectColorOptions.forEach(function (elem) {
					elem.addEventListener("mousedown", function (e) {
						bind(e, el, elem);
						e.preventDefault();
						common.SaveImprintColor(elem);
					});
				});
			}
		});
		
		document.getElementsByTagName("body")[0].addEventListener("click", function (e) {
			var selectColorOptions = [].slice.call(document.querySelectorAll(".prodColorOptions"));
			selectColorOptions.forEach(function (el) {
				if (!el.contains(e.target) && el.querySelector(".colorOptions") && el.querySelector(".colorOptions").classList.contains("openDropDown")) {
					closeOnBlur(e, el);
				}
			});
		});
	},
	bindActiveInput = (e, selector) => {
		let colorOptionList = selector.querySelector(".colorOptions");
		let colorInput = selector.querySelector(".prodColorOptions");
		colorOptionList.classList.remove("hide");
		colorOptionList.setAttribute("aria-hidden", "false");
		var newHeight = (parseInt(selector.getElementsByClassName("prodColorOptions")[0].querySelectorAll('.colorOptionsContainer').length) + 1) * 26;
		colorOptionList.style.height = newHeight < 200 ? newHeight + "px" : "200px";
		colorOptionList.style.overflowY = "scroll";
		colorOptionList.style.overflowX = "hidden";
		colorOptionList.style.display = "block";
		colorOptionList.style.paddingBottom = "3px";
		colorOptionList.classList.add("openDropDown");
		colorInput.setAttribute("aria-expanded", "true");
		colorOptionList.classList.remove("closedDropDown");
		
		colorInput.removeEventListener('keydown', closeKeyEvent);
		colorInput.addEventListener('keydown', openKeyEvent);
		
		if (selector.querySelector(".imprintClearColor")) selector.querySelector(".imprintClearColor").classList.add("hide");
		if (selector.querySelector(".input_active").querySelector(".colorOptionId")) { // moves selected dropdown option to first in visual field 
			var colorID = selector.querySelector(".input_active").querySelector(".colorOptionId").innerHTML;
			selector.querySelector(".colorOptions").querySelector(".colorId_" + colorID).firstElementChild.classList.add("mimicHover");
			var topPos = selector.querySelector(".colorOptions").querySelector(".colorId_" + colorID).firstElementChild.offsetTop;
			selector.querySelector(".colorOptions").scrollTop = topPos - 40;
		}
		keyEvents();
	},
	keyEvents = () => { // keyboard accessibility for custom color "dropdown"
		var colorDropDownLabels = [].slice.call(document.querySelectorAll(".impColorSelectLabel"));
		colorDropDownLabels.forEach(function (el) {
			el.addEventListener('click', function (e) {
				el.nextElementSibling.firstElementChild.focus();
			});
		});
		var activeInputFields = [].slice.call(document.querySelectorAll(".prodColorOptions"));
		activeInputFields.forEach(function (el, index) {
			if (el.querySelector(".colorOptions").classList.contains("openDropDown")) {
				el.addEventListener('keydown', openKeyEvent);
			} else if (el.querySelector(".colorOptions").classList.contains("closedDropDown")) {
				el.addEventListener('keydown', closeKeyEvent);
			}
			el.addEventListener("blur", function (e) {
				predictiveText = '';
			});
		});
		var impColorBtns = [].slice.call(document.querySelectorAll(".spnColorChip"));
		impColorBtns.forEach(function (el) {
			el.addEventListener('keydown', function (e) {
				e.preventDefault();
				e.stopPropagation();
				let currentIndex, colorChips;
				if ((e.which && e.which == 27) || (e.keyCode && e.keyCode == 27)) {
					closeOnBlur(e, el.closest(".prodColorOptions"));
					el.closest(".prodColorOptions").focus();
				} else if (((e.which && e.which == 38) || (e.keyCode && e.keyCode == 38)) && el.parentNode.previousElementSibling) {
					el.classList.remove("mimicHover");
					if (!el.parentNode.previousElementSibling.classList.contains("hide")) {
						mimicFocus(el.parentNode.previousElementSibling.firstElementChild);
					} else {
						currentIndex = 10000;
						for (var i = 0; i < el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer").length; i++) {
							if (el.parentNode.previousElementSibling == el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[i]) {
								currentIndex = i;
							}
							if (i >= currentIndex && el.closest(".prodColorOptions").querySelectorAll(".colorOptionsContainer")[i].previousElementSibling && !el.closest(".prodColorOptions").querySelectorAll(".colorOptionsContainer")[i].previousElementSibling.classList.contains("hide")) {
								mimicFocus(el.closest(".prodColorOptions").querySelectorAll(".colorOptionsContainer")[i].previousElementSibling.firstElementChild);
								currentIndex = 10000;
							}
						}
					}
				} else if (((e.which && e.which == 40) || (e.keyCode && e.keyCode == 40)) && el.parentNode.nextElementSibling) {
					el.classList.remove("mimicHover");
					if (!el.parentNode.nextElementSibling.classList.contains("hide")) {
						mimicFocus(el.parentNode.nextElementSibling.firstElementChild);
					} else {
						currentIndex = 10000;
						for (var i = 0; i < el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer").length; i++) {
							if (el.parentNode.nextElementSibling == el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[i]) {
								currentIndex = i;
							}
							if (i >= currentIndex && !el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[i].classList.contains("hide")) {
								mimicFocus(el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[i].firstElementChild);
								currentIndex = 10000;
							}
						}
					}
				} else if ((e.which && e.which == 35) || (e.keyCode && e.keyCode == 35)) { // end
					colorChips = [].slice.call(el.closest(".colorOptions").querySelectorAll(".spnColorChip"));
					colorChips.forEach(function (el) {
						el.classList.remove("mimicHover");
					});
					var lastOne = el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer").length - 1];
					while (lastOne && lastOne.classList.contains("colorOptionsContainer")) {
						if (!lastOne.classList.contains("hide")) {
							mimicFocus(lastOne.querySelector(".spnColorChip")); break;
						}
						lastOne = lastOne.previousElementSibling;
					}
				} else if ((e.which && e.which == 36) || (e.keyCode && e.keyCode == 36)) { // home
					colorChips = [].slice.call(el.closest(".colorOptions").querySelectorAll(".spnColorChip"));
					colorChips.forEach(function (el) {
						el.classList.remove("mimicHover");
					});
					var firstOne = el.closest(".colorOptions").querySelectorAll(".colorOptionsContainer")[0];
					while (firstOne && firstOne.classList.contains("colorOptionsContainer")) {
						if (!firstOne.classList.contains("hide")) {
							mimicFocus(firstOne.querySelector(".spnColorChip")); break;
						}
						firstOne = firstOne.nextElementSibling;
					}
				} else if (((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) && el.closest(".prodColorOptions").querySelector(".colorOptions").classList.contains("openDropDown")) {
					bind(e, el.closest(".imprint-color-section"), el);
					common.SaveImprintColor(el); 
					el.closest(".prodColorOptions").querySelector(".colorOptions").classList.remove("openDropDown");
					el.closest(".prodColorOptions").querySelector(".colorOptions").classList.add("closedDropDown");
					el.closest(".prodColorOptions").removeEventListener('keydown', openKeyEvent);
					el.closest(".prodColorOptions").addEventListener('keydown', closeKeyEvent);
					el.closest(".prodColorOptions").focus();
					el.closest(".prodColorOptions").setAttribute("aria-expanded", "false");
				}
			});
		});
	},
	openKeyEvent = (e) => {
		var el = e.currentTarget, colorID, colorChips, currentOne;
		e.preventDefault();
		e.stopPropagation();
		basicKeyEvents(e, el);
		if (((e.which && e.which == 40) || (e.keyCode && e.keyCode == 40)) && el.querySelector(".input_active").nextElementSibling) { // down arrow 
			if (el.querySelector(".input_active").nextElementSibling.firstElementChild && !el.querySelector(".input_active").nextElementSibling.firstElementChild.classList.contains("hide")) {
				if (el.querySelector(".input_active").querySelector(".input_placeholder")) {
					mimicFocus(el.querySelector(".input_active").nextElementSibling.firstElementChild.firstElementChild);
				} else {
					colorID = el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim();
					el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).firstElementChild.classList.remove("mimicHover");
					mimicFocus(el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).nextElementSibling.firstElementChild);
				}
			} else {
				var currentIndex = 10000;
				for (var i = 0; i < el.parentNode.querySelectorAll(".colorOptionsContainer").length; i++) {
					if (el.querySelector(".input_active").querySelector(".input_placeholder")) {
						if (el.querySelector(".input_active").nextElementSibling.firstElementChild == el.parentNode.querySelectorAll(".colorOptionsContainer")[i]) {
							currentIndex = i;
						}
						if (i >= currentIndex && !el.parentNode.querySelectorAll(".colorOptionsContainer")[i].classList.contains("hide")) {
							mimicFocus(el.parentNode.querySelectorAll(".colorOptionsContainer")[i].firstElementChild);
							currentIndex = 10000;
						}
					} else {
						colorID = el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim();
						if (el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID) == el.parentNode.querySelectorAll(".colorOptionsContainer")[i]) {
							currentIndex = i;
						}
						if (i >= currentIndex && !el.parentNode.querySelectorAll(".colorOptionsContainer")[i].classList.contains("hide")) {
							mimicFocus(el.parentNode.querySelectorAll(".colorOptionsContainer")[i].firstElementChild);
							currentIndex = 10000;
						}
					}
				}
			}
		} else if (((e.which && e.which == 38) || (e.keyCode && e.keyCode == 38)) && el.querySelector(".input_active").querySelector(".colorOptionId")) { // up arrow
			colorID = el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim();
			if (!el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).previousElementSibling.classList.contains("hide")) {
				el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).firstElementChild.classList.remove("mimicHover");
				el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).previousElementSibling.firstElementChild.classList.remove("mimicHover");
				mimicFocus(el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).previousElementSibling.firstElementChild);
			} else {
				colorID = el.querySelector(".input_active").querySelector(".colorOptionId") ? el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim() : 0;
				if (colorID != 0) {
					currentOne = el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).querySelector(".spnColorChip").parentNode;
					while (currentOne && currentOne.previousElementSibling && currentOne.previousElementSibling.classList.contains("colorOptionsContainer")) {
						if (!currentOne.previousElementSibling.classList.contains("hide")) {
							el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).querySelector(".spnColorChip").classList.remove("mimicHover");
							mimicFocus(currentOne.previousElementSibling.firstElementChild); break;
						}
						currentOne = currentOne.previousElementSibling;
					}
				}
			}
		} else if ((e.which && e.which == 35) || (e.keyCode && e.keyCode == 35)) { // end
			colorChips = [].slice.call(el.querySelectorAll(".spnColorChip"));
			colorChips.forEach(function (el) {
				el.classList.remove("mimicHover");
			});
			var lastOne = el.parentNode.querySelectorAll(".colorOptionsContainer")[el.parentNode.querySelectorAll(".colorOptionsContainer").length - 1];
			while (lastOne && lastOne.classList.contains("colorOptionsContainer")) {
				if (!lastOne.classList.contains("hide")) {
					mimicFocus(lastOne.querySelector(".spnColorChip")); break;
				}
				lastOne = lastOne.previousElementSibling;
			}
		} else if ((e.which && e.which == 36) || (e.keyCode && e.keyCode == 36)) { // home
			colorChips = [].slice.call(el.querySelectorAll(".spnColorChip"));
			colorChips.forEach(function (el) {
				el.classList.remove("mimicHover");
			});
			var firstOne = el.parentNode.querySelectorAll(".colorOptionsContainer")[0];
			while (firstOne && firstOne.classList.contains("colorOptionsContainer")) {
				if (!firstOne.classList.contains("hide")) {
					mimicFocus(firstOne.querySelector(".spnColorChip")); break;
				}
				firstOne = firstOne.nextElementSibling;
			}
		}
	},
	closeKeyEvent = (e) => {
		var el = e.currentTarget, currentOne, colorID;
		e.preventDefault();
		basicKeyEvents(e, el);
		var eventObject = document.createEvent("MouseEvents");
		eventObject.initEvent("mousedown", true, false);
		
		if ((e.which && e.which == 27) || (e.keyCode && e.keyCode == 27)) {
			closeOnBlur(e, el);
		} else if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13) || (e.which && e.which == 32) || (e.keyCode && e.keyCode == 32)) { // opens color dropdown on enter and space
			bindActiveInput(e, el.closest(".imprint-color-section"));
		} else if ((e.which && e.which == 40) || (e.keyCode && e.keyCode == 40)) { // down arrow
			colorID = el.querySelector(".input_active").querySelector(".colorOptionId") ? el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim() : 0;
			if (colorID != 0) {
				currentOne = el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).querySelector(".spnColorChip").parentNode;
				while (currentOne && currentOne.nextElementSibling && currentOne.nextElementSibling.classList.contains("colorOptionsContainer")) {
					if (!currentOne.nextElementSibling.classList.contains("hide")) {
						currentOne.nextElementSibling.querySelector(".spnColorChip").dispatchEvent(eventObject); break;
					}
					currentOne = currentOne.nextElementSibling;
				}
			} else {
				currentOne = el.parentNode.querySelectorAll(".colorOptionsContainer")[0];
				while (currentOne && currentOne.classList.contains("colorOptionsContainer")) {
					if (!currentOne.classList.contains("hide")) {
						currentOne.querySelector(".spnColorChip").dispatchEvent(eventObject); break;
					}
					currentOne = currentOne.nextElementSibling;
				}
			}
		} else if ((e.which && e.which == 38) || (e.keyCode && e.keyCode == 38)) { // up arrow
			colorID = el.querySelector(".input_active").querySelector(".colorOptionId") ? el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim() : 0;
			if (colorID != 0) {
				currentOne = el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).querySelector(".spnColorChip").parentNode;
				while (currentOne && currentOne.previousElementSibling && currentOne.previousElementSibling.classList.contains("colorOptionsContainer")) {
					if (!currentOne.previousElementSibling.classList.contains("hide")) {
						currentOne.previousElementSibling.querySelector(".spnColorChip").dispatchEvent(eventObject); break;
					}
					currentOne = currentOne.previousElementSibling;
				}
			}
		} else if ((e.which && e.which == 35) || (e.keyCode && e.keyCode == 35)) { // end
			var lastOne = el.parentNode.querySelectorAll(".colorOptionsContainer")[el.parentNode.querySelectorAll(".colorOptionsContainer").length - 1];
			while (lastOne && lastOne.classList.contains("colorOptionsContainer")) {
				if (!lastOne.classList.contains("hide")) {
					lastOne.querySelector(".spnColorChip").dispatchEvent(eventObject); break;
				}
				lastOne = lastOne.previousElementSibling;
			}
		} else if ((e.which && e.which == 36) || (e.keyCode && e.keyCode == 36)) { // home
			var firstOne = el.parentNode.querySelectorAll(".colorOptionsContainer")[0];
			while (firstOne && firstOne.classList.contains("colorOptionsContainer")) {
				if (!firstOne.classList.contains("hide")) {
					firstOne.querySelector(".spnColorChip").dispatchEvent(eventObject); break;
				}
				firstOne = firstOne.nextElementSibling;
			}
		}
	},
	mimicFocus = (elem) => {
		elem.focus(); 
		elem.classList.add("mimicHover");
	},
	closeOnBlur = (e, el) => {
		e.preventDefault();
		e.stopPropagation();
		el.setAttribute("aria-expanded", "false");
		if (el.parentNode.querySelector(".imprintClearColor") && (!el.querySelector(".input_placeholder") || el.querySelector(".input_placeholder").innerHTML.indexOf("Additional") != -1)) {
			el.parentNode.querySelector(".imprintClearColor").classList.remove("hide");
		}
		if (el.querySelector(".colorOptions")) {
			el.querySelector(".colorOptions").classList.add("hide");
			el.querySelector(".colorOptions").removeAttribute("style");
			el.querySelector(".colorOptions").setAttribute("aria-hidden", "true");
			el.querySelector(".colorOptions").classList.remove("openDropDown");
			el.querySelector(".colorOptions").classList.add("closedDropDown");
			el.removeEventListener('keydown', openKeyEvent);
			el.addEventListener('keydown', closeKeyEvent);
		}
		var colorOptionContainers = [].slice.call(el.querySelectorAll(".spnColorChip"));
		colorOptionContainers.forEach(function (el) {
			el.removeAttribute("style");
			el.classList.remove("mimicHover");
		});
		predictiveText = "";
	},
	basicKeyEvents = (e, el) => {
		if ((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode == 46) {
			predictiveText += String.fromCharCode(e.keyCode).toLowerCase();
			var colorOptions = [].slice.call(el.querySelectorAll(".colorOptionDesc"));
			for (var i = 0; i < colorOptions.length; i++) {
				if (colorOptions[i].innerHTML.toLowerCase().indexOf(predictiveText) != -1 && colorOptions[i].closest(".spnColorChip") && !colorOptions[i].closest(".spnColorChip").classList.contains("input_active") && !colorOptions[i].closest(".colorOptionsContainer").classList.contains("hide")) {
					var mimicHover = [].slice.call(el.querySelectorAll(".mimicHover"));
					mimicHover.forEach(function (el) {
						el.classList.remove("mimicHover");
					});
					var eventObject = document.createEvent("MouseEvents");
					eventObject.initEvent("mousedown", true, false);
					bind(eventObject, el.closest(".imprint-color-section"), colorOptions[i].closest(".spnColorChip"));
					common.SaveImprintColor(colorOptions[i].closest(".spnColorChip")); break; 
				}
			}
		} else if ((e.which && e.which == 27) || (e.keyCode && e.keyCode == 27)) { // esc
			closeOnBlur(e, el);
		} else if (((e.which && e.which == 9) || (e.keyCode && e.keyCode == 9)) && !e.shiftKey) { // tab
			if (!el.nextElementSibling.classList.contains("hide")) {
				el.nextElementSibling.focus();
			} else if (el.nextElementSibling.classList.contains("hide")) {
				if (!el.closest(".imprintConfiguration").querySelector(".addImpColor").classList.contains("hide")) {
					el.closest(".imprintConfiguration").querySelector(".addImpColor").focus();
				} else if (!document.getElementsByClassName("addImpLocation")[0].classList.contains("hide")) {
					document.getElementsByClassName("addImpLocation")[0].focus();
				} else if (document.getElementById("NibOptions") && !document.getElementById("NibOptions").disabled && document.getElementById("NibOptions").options.length > 0) {
					document.getElementById("NibOptions").focus();
				} else if (document.getElementById("inkColorOptions") && !document.getElementById("inkColorOptions").disabled && document.getElementById("inkColorOptions").options.length > 0) {
					document.getElementById("inkColorOptions").focus();
				} else if (document.getElementById("adhOptions") && !document.getElementById("adhOptions").disabled && document.getElementById("adhOptions").options.length > 0) {
					document.getElementById("adhOptions").focus();
				} else if (document.getElementById("adhSideOptions") && !document.getElementById("adhSideOptions").disabled && document.getElementById("adhSideOptions").options.length > 0) {
					document.getElementById("adhSideOptions").focus();
				} else {
					//document.getElementsByClassName("btnAddToCart")[0].focus();
					document.getElementById("Step2ArtworkAlertBox").querySelector("a").focus(); 
				}
			}
		} else if (((e.which && e.which == 9) || (e.keyCode && e.keyCode == 9)) && e.shiftKey) { // tab - shift
			if (el.closest(".imprint-color-section").previousElementSibling.previousElementSibling.getElementsByClassName("imprintClearColor")[0] && !el.closest(".imprint-color-section").previousElementSibling.previousElementSibling.classList.contains("hide")) {
				el.closest(".imprint-color-section").previousElementSibling.previousElementSibling.getElementsByClassName("imprintClearColor")[0].focus();
			} else if (el.closest(".imprint-color-section").parentNode.querySelector(".selImprintLocation").disabled == false) {
				el.closest(".imprint-color-section").parentNode.querySelector(".selImprintLocation").focus();
			} else if (!el.closest(".imprint-color-section").parentNode.querySelector(".delImpConfigSection").parentNode.classList.contains("hide") && el.closest(".imprint-color-section").parentNode.querySelector(".delImpConfigSection")) {
				el.closest(".imprint-color-section").parentNode.querySelector(".delImpConfigSection").focus();
			} else {
				document.getElementsByClassName("goToStep1")[0].focus();
			}
		} else if (((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) && el.querySelector(".colorOptions").classList.contains("openDropDown")) {
			var colorID = el.querySelector(".input_active").querySelector(".colorOptionId") ? el.querySelector(".input_active").querySelector(".colorOptionId").innerHTML.trim() : 0;
			if (colorID != 0) {
				el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).firstElementChild.classList.remove("mimicHover");
				bind(e, el.closest(".imprint-color-section"), el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).firstElementChild);
				common.SaveImprintColor(el.querySelector(".input_active").nextElementSibling.querySelector(".colorId_" + colorID).firstElementChild); 
			} else {
				closeOnBlur(e, el);
			}
			el.querySelector(".colorOptions").classList.remove("openDropDown");
			el.querySelector(".colorOptions").classList.add("closedDropDown");
			el.removeEventListener('keydown', openKeyEvent);
			el.addEventListener('keydown', closeKeyEvent);
			el.focus();
			el.setAttribute("aria-expanded", "false");
		}
	},
	bind = (e, selector, el) => {
		var imprintSection = selector.querySelector(".prodColorOptions").closest(".imprintConfiguration");
		var previouslySelectedColor = selector.getElementsByClassName("input_active")[0];
		var colorOptionId = 0;
		if (selector.getElementsByClassName("input_active")[0].getElementsByClassName("colorOptionId")[0]) {
			colorOptionId = selector.getElementsByClassName("input_active")[0].getElementsByClassName("colorOptionId")[0].innerHTML;
		}
		if ((selector.getElementsByClassName("input_placeholder")[0]) ||
			selector.getElementsByClassName("input_active")[0].querySelector('.colorOptionId') && selector.getElementsByClassName("input_active")[0].querySelector('.colorOptionId').innerHTML != el.querySelector(".colorOptionId").innerHTML) {
			for (var i = 0; i < imprintSection.querySelectorAll(".prodColorOptions").length; i++) {
				if (imprintSection.querySelectorAll(".prodColorOptions")[i] != selector.querySelector(".prodColorOptions")) {
					var allColors = [].slice.call(imprintSection.querySelectorAll(".prodColorOptions")[i].querySelectorAll('.colorOptionsContainer'));
					for (var j = 0; j < allColors.length; j++) {
						if (allColors[j].querySelector('.colorOptionId').innerHTML == el.querySelector(".colorOptionId").innerHTML) {
							allColors[j].classList.add("hide");
						}
					}
				}
			}
			if (!previouslySelectedColor.querySelector(".input_placeholder")) {
				var allProdColors = [].slice.call(imprintSection.querySelectorAll(".prodColorOptions"));
				allProdColors.forEach(function (el) {
					if (el != selector.querySelector(".prodColorOptions") || (prodConfig.EditID > -1)) {
						if(el.getElementsByClassName("colorId_" + colorOptionId)[0]){
							el.getElementsByClassName("colorId_" + colorOptionId)[0].classList.remove("hide");
						}
					}
				});
			}
		}
		el.setAttribute("aria-selected", "true");
		var btns = [].slice.call(selector.querySelectorAll(".spnColorChip"));
		btns.forEach(function (btnElem) {
			if (el != btnElem) {
				btnElem.setAttribute("aria-selected", "false");
			}
		});
		selector.querySelector(".input_active").innerHTML = el.innerHTML;
		common.UpdateRemoveColorText(selector);
		selector.querySelector(".input_active").querySelector(".colorOptionText").classList.add("txtToValidate");
		selector.querySelector(".prodColorOptions").classList.remove("txtReq");
		//selector.querySelector(".prodColorOptions").setAttribute("title", selector.querySelector(".input_active").querySelector(".colorOptionDesc").innerHTML);
		selector.querySelector(".colorOptions").classList.add("hide");
		selector.querySelector(".colorOptions").removeAttribute("style");
		if (!selector.querySelector(".prodColorOptions").classList.contains("disabledColorSelect")) {
			selector.querySelector(".imprintClearColor").classList.remove("hide");
		}
	};
	return{
		Init: init,
		KeyEvents: keyEvents
	};
};