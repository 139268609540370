/* updated dialog if not ready to use module yet */

var Dialog4i = function () { 
	const mainBody = document.getElementsByClassName("mainBodyWrapper");
	const chatWrap = document.getElementById("iwc-chat-wrapper");
	const emailWrap = document.getElementById("iwc-email-wrapper");
	const contactBtns = document.getElementById("iwc-contactPanel");
	
	let triggerEls, dialog, dialogClass, dialogContent, dialogTitle, dialogHeaderHTML, update, dialogHTML,
	dialogLeftArrow = "", dialogRightArrow = "", focusOnOpen = "", openCallBack = "", keyFunctions = "", closeCallBack = "";
	
	const init = (options) => {
		if (typeof options !== 'undefined'){
			// options that must be included
       		triggerEls = options.triggerElements;
	       	dialogClass = options.dialogClass;
	       	dialogContent = options.content;
	            	dialogTitle = options.title;
	            	dialogHeaderHTML = buildDialogHdr();
	            	
	            	// optional options
	            	if (typeof options.focusOnOpen !== 'undefined') focusOnOpen = options.focusOnOpen;
			if (typeof options.update !== 'undefined') update = options.update; //so far only used on video dialogs
			if (typeof options.onOpen !== 'undefined') openCallBack = options.onOpen;
			if (typeof options.onClose !== 'undefined') closeCallBack = options.onClose; // happens on close4iDialog items
			if (typeof options.keyEvents !== 'undefined') keyFunctions = options.keyEvents;
			if (typeof options.arrows !== 'undefined') {
	            		dialogLeftArrow = options.arrows.left;
	           	 	dialogRightArrow = options.arrows.right;
	       	}
			
			// build dialog
			dialogHTML = buildDialog();
			bindTriggerElements(triggerEls);
           	}
	};
	
	const bindTriggerElements = (elements) => {
		if(Array.isArray(elements) && !elements.length){ return; } //if is an empty array stop here

		if(elements.length > 0){
	    	 	for(let i = 0; i < elements.length; i++){
                		elements[i].removeEventListener("click", open);
               		elements[i].addEventListener("click", open);
                 	}
	    	} else {
	    		elements.removeEventListener("click", open);
	             	elements.addEventListener("click", open);
	    	}
	};
	
	const buildDialogHdr = () => {
		const headerHTML =
        		'<div class="dialogHeader displayFlex flexSpaceBetween flexNoWrap flexAlignItemsCenter">' +
                        	'<h2 id="dialogHdrDesc">' + dialogTitle + '</h2>' +
	            		'<button type="button" class="close4iDialog closeIcon roundCorners3">' +
                        		'<svg class="show roundCorners3 padding5 margin10" height="15px" width="15px" focusable="false" role="img" aria-label="Close dialog"><title>Close dialog</title><use xlink:href="#svgIcon-remove"></use></svg>' +
                       	'</button>' +
              	'</div>';
         	return headerHTML;
 	};
 	
	const buildDialog = () => {
            	let dialogHTML = '<div id="popUpDialog" class="dialogWrapper showDialog animate animateFadeIn animateFaster" role="dialog" aria-modal="true" aria-labelledby="dialogHdrDesc">';
      		if (dialogLeftArrow !== "") dialogHTML += dialogLeftArrow;

		dialogHTML +=
                    	'<div class="' + dialogClass + '">' +
                    		dialogHeaderHTML +
                    		'<div class="dialogContent">' + dialogContent + '</div>' +
                    	'</div>';
                
		if (dialogRightArrow !== "") dialogHTML += dialogRightArrow;

		dialogHTML += '<div>';
		return dialogHTML;
	};
	
	/*const updateTriggerEl = (element) =>{
		const trigger = document.querySelector(".dialogTrigger");
		trigger.setAttribute("aria-expanded", "false");
            	trigger.classList.remove("dialogTrigger");
		element.classList.add("dialogTrigger");
     		element.setAttribute("aria-expanded", "true");
	};*/
	
	const open = (e, el) => {
		if(document.getElementById("popUpDialog")) close();
		
		if(typeof el !== 'undefined'){
			el.classList.add("dialogTrigger");			
			document.querySelector(".dialogTrigger").setAttribute("aria-expanded", "true");
		}else if(typeof e !== 'undefined'){
			e.preventDefault();
			if(e.currentTarget) e.currentTarget.classList.add("dialogTrigger");	
		}
		const triggerEl = document.getElementsByClassName("dialogTrigger");
		if(triggerEl.length > 0) triggerEl[0].setAttribute("aria-expanded", "true");
     		
          	document.body.insertAdjacentHTML("beforeend", dialogHTML);
          	dialog = document.getElementById("popUpDialog");

        	//remove all other content from being interacted with
   		inertContent();
            	bindEvents();
            	
            	if(openCallBack !== "") {
			//if(openCallBack.constructor.name === "AsyncFunction") await openCallBack(document.querySelector(".dialogTrigger")); // await an async binddata function
			openCallBack(document.querySelector(".dialogTrigger"));
		}
		// sometimes focus doesn't happen, so setting after a timeout
		setTimeout(() => {
  			if(focusOnOpen !== "") focusOnOpen.focus();
            		else dialog.querySelector(".close4iDialog").focus(); //close icon
		}, 100);
    	};
    	
	const close = (e) => {
 		if(typeof e !== 'undefined'){
 			e.preventDefault();
 			if(closeCallBack !== "" && e.currentTarget.classList.contains("close4iDialog")) closeCallBack();
 		}
		//remove inert from main content
		reverseInert(); 
		let dialog = document.getElementById("popUpDialog");
          	if(dialog) dialog.remove();
          	const triggerEl = document.getElementsByClassName("dialogTrigger");
          	if(triggerEl.length > 0){
        		triggerEl[0].setAttribute("aria-expanded", "false");
        		// sometimes focus doesn't happen, so setting after a timeout
	         	setTimeout(() => {
	  			if(triggerEl[0]){ // there are times this isn't here if things are being deleted
		  			triggerEl[0].focus();
		         		triggerEl[0].classList.remove("dialogTrigger");
	         		}
			}, 100);
        	}
     	};
     	
     	const bindEvents = () => {
     		bindCloseElements();
             dialog.addEventListener("keyup", closeOnEscape);
           	if(keyFunctions !== "") dialog.addEventListener("keydown", keyFunctions);
	};
	
	const bindCloseElements = () => {
		const closeElements = document.getElementById("popUpDialog").querySelectorAll(".close4iDialog");
     		for(let x = 0; x < closeElements.length; x++){
     			closeElements[x].removeEventListener("click", close);
          	 	closeElements[x].addEventListener("click", close);
          	}
	};
	
	const closeOnEscape = (e) => {
	 	if (e.which == 27) {
			if(closeCallBack !== "") closeCallBack();
	 		close(e);
	 	}
    	};
    	
    	const hideCloseBtn = () => {
    		const closeBtn = document.getElementById("popUpDialog").querySelector(".close4iDialog");
    		closeBtn.classList.add("hide");
    	};
    	
	const showCloseBtn = () => {
    		const closeBtn = document.getElementById("popUpDialog").querySelector(".close4iDialog");
    		closeBtn.classList.remove("hide");
    	};
    	
	const inertContent = () => {
            	if(mainBody[0]) mainBody[0].inert = true;
            	if(chatWrap) chatWrap.inert = true;
            	if(emailWrap) emailWrap.inert = true;
            	if(contactBtns) contactBtns.inert = true;
	};
	
	const reverseInert = () => {
            	if(mainBody[0]) mainBody[0].inert = false;
            	if(chatWrap) chatWrap.inert = false;
            	if(emailWrap) emailWrap.inert = false;
            	if(contactBtns) contactBtns.inert = false;
	};
	
	return{
		Init: init, 
		Open: open, 
		Close: close,
		HideCloseBtn: hideCloseBtn,
		ShowCloseBtn: showCloseBtn,
		BindTriggerElements: bindTriggerElements,
		BindCloseElements: bindCloseElements
	};
};