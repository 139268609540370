(function() {
	'use strict';
	
	const contactPanel = function() {
		var openHide, openEmail, openChat, hidden = false;

		const init = function() {
			fetch('/Chat/GetContactForm', {
				method: 'GET',
				credentials: 'same-origin',
				cache: 'no-cache'
			}).then(function(response) {
				return (response.ok) ? response.text() : []; 
			}).then(function(response) {
				if (response) {
					const div = document.createElement('div');
					div.innerHTML = response;
					document.getElementsByTagName('body')[0].appendChild(div);
					openHide = document.querySelector('.iwc-contactPanel .iwc-openHide');
					openEmail = document.querySelector('.iwc-contactPanel .iwc-openEmail');
					openChat = document.querySelector('.iwc-contactPanel .iwc-openChat');
					openHide.addEventListener('click', hide);
					openEmail.addEventListener('click', toggleEmail); //showEmail
					openChat && openChat.addEventListener('click', toggleChat); //showChat
					const checkChat = JSON.parse(sessionStorage.getItem("chatState"));
					if (openChat && checkChat && checkChat.status === 1) {
						(!checkChat.inPopout && (new Date() - new Date(checkChat.time)) <= 120000) ? openChat.click() : sessionStorage.removeItem('chatState');
					}
				} else {
					logError('ContactPanel: failed getting contact form', { 'Error': 'Form came back empty' });
				}
					
				addChatEvents();
			}).catch(function(error) {
				logError('ContactPanel: failed getting contact form', { 'Error': JSON.stringify(error) });
				addChatEvents();
			});
		}

		const hide = function(e) {
			if (hidden) {
				openHide.querySelector('.hideLabel').classList.remove('hide');
				openHide.querySelector('.showLabel').classList.add('hide');
				openHide.querySelector("svg").setAttribute("class", "rotate180 show layoutCenter");
				openHide.classList.add('padding5');
				openHide.setAttribute('aria-expanded', 'true');
				openChat && openChat.querySelector('span').classList.remove('hide');
				openChat && openChat.classList.add('padding5');
				openChat && openChat.classList.remove('invisible');
				openEmail.querySelector('span').classList.remove('hide');
				openEmail.classList.add('padding5')
				openEmail.classList.remove('invisible');
			} else {
				openHide.querySelector('.hideLabel').classList.add('hide');
				openHide.querySelector('.showLabel').classList.remove('hide');
				openHide.querySelector("svg").setAttribute("class", "");
				openHide.classList.remove('padding5');
				openHide.setAttribute('aria-expanded', 'false');
				openChat && openChat.querySelector('span').classList.add('hide');
				openChat && openChat.classList.remove('padding5');
				openChat && openChat.classList.add('invisible');
				openEmail.querySelector('span').classList.add('hide');
				openEmail.classList.remove('padding5');
				openEmail.classList.add('invisible');
				if (document.getElementById("chatErrorMsg")) {
					document.getElementById("chatErrorMsg").innerHTML = '';
				}
			}

			hidden = !hidden;
		}

		const toggleEmail = function(e) {
			e.preventDefault();
			if(document.getElementById('iwc-email-wrapper').classList.contains("open")){
				hideEmail();
			}else{
				if(document.getElementById("iwc-startSection")) hideChat(); // if first part of chat is open, close it
				showEmail();
			}
		}
		
		const hideEmail = function() {
			const email = document.getElementById('iwc-email-wrapper');			
			email.classList.remove("open");
			unSelectButton(openEmail);
		}
		
		const showEmail = function() {
			const email = document.getElementById('iwc-email-wrapper');
			email.classList.add("open");
			email.innerHTML = document.getElementsByClassName('loadingIconDiv')[0].innerHTML;

			fetch('/Chat/EmailUs', {
				method: 'GET',
				credentials: 'same-origin',
				cache: 'no-cache'
			}).then(function(response) {
				return (response.ok) ? response.text() : [];
			}).then(function(response) {
				email.insertAdjacentHTML('beforeend', response);
				var script = document.getElementById('emailScript');
				script && script.parentNode.removeChild(script);
				script = document.createElement('script');
				script.src = document.getElementById('iwc_JsEmailPath').value;
				script.type = 'text/javascript';
				script.async = true;
				script.id = 'emailScript';
				script.setAttribute('crossorigin', 'anonymous');
				document.getElementsByTagName('head')[0].appendChild(script);
				selectButton(openEmail);
			}).catch(function(error) {
				logError('ContactPanel: failed getting email us form', { 'Error': JSON.stringify(error) });
				email.classList.remove("open");
			});
		}
		
		const toggleChat = function(e){
			e.preventDefault();
			if(document.getElementById('iwc-chat-wrapper').classList.contains("open")){
				hideChat();
			}else {
				hideEmail(); //if email is open, close it
				showChat();
			}
		}
		
		const hideChat = function(){
			const chat = document.getElementById('iwc-chat-wrapper');
			chat.classList.remove("open");
			unSelectButton(openChat);
			openChat.classList.add('pointer');
			openChat.disabled = false;
			if (document.getElementById("chatErrorMsg")) {
				document.getElementById("chatErrorMsg").parentNode.removeChild(document.getElementById("chatErrorMsg"));
			}
		}

		const showChat = function() {
			omniture.ChatBtnClick();
			const chat = document.getElementById('iwc-chat-wrapper');
			chat.classList.add("open");
			chat.innerHTML = document.getElementsByClassName('loadingIconDiv')[0].innerHTML;

			fetch('/Chat/GetChatForm', {
				method: 'GET',
				credentials: 'same-origin',
				cache: 'no-cache'
			}).then(function(response) {
				return (response.ok) ? response.text() : []; 
			}).then(function(response) {
				chat.insertAdjacentHTML('beforeend', response);
				if (document.getElementById('iwc_JsPath')) {
					var script = document.getElementById('chatScript');
					script && script.parentNode.removeChild(script);
					var head = document.getElementsByTagName('head')[0];
					script = document.createElement('script');
					script.src = document.getElementById('iwc_JsPath').value;
					script.id = 'chatScript'
					script.type = 'text/javascript';
					script.async = true;
					script.setAttribute('crossorigin', 'anonymous');
					head.appendChild(script);
					selectButton(openChat);
					openChat.classList.remove('pointer');
					openChat.disabled = true;
				} else {
					if (document.getElementById("chatErrorMsg")) {
						document.getElementById("chatErrorMsg").parentNode.removeChild(document.getElementById("chatErrorMsg"));
					}
					document.getElementsByClassName('iwc-contactPanel')[0].insertAdjacentHTML('beforeend' , 
						'<div class="displayFlex flexNoWrap textRed textCenter textSemiBold padding5" id="chatErrorMsg">Chat unavailable.</div>')
				}
			}).catch(function(error) {
				logError('ContactPanel: failed getting chat form', { 'Error': error });
				chat.classList.remove("open");
			});
		}
		
		const selectButton = function(button) {
			button.classList.add('bkgdBlue');
			button.classList.add('whiteLink');
			button.classList.remove('btnToHyp');
			button.setAttribute('aria-expanded', 'true');
			//button.classList.remove('pointer');
			//button.disabled = true;
		}
		
		const unSelectButton = function(button){
			button.classList.remove('bkgdBlue');
			button.classList.remove('whiteLink');
			button.classList.add('btnToHyp');
			button.setAttribute('aria-expanded', 'false');
		}

		const omniture = (function() {
			const chatBtnClick = function() {
				const account = typeof s_account === 'string' ? s_account : '4imprintprod';
				const s = typeof s_gi === 'function' ? s_gi(account) : '';
				if (s) {
					s.linkTrackVars = 'prop28';
					s.linkTrackEvents = 'None';
					s.prop28 = 'Chat Sessions Log'; //Chat traffic, log click event
					s.tl(true, 'o', 'Chat Sessions');
				}
			};

			return {
				ChatBtnClick: chatBtnClick
			};
		})();

		const logError = function(message, properties) {
			typeof appInsights !== 'undefined' && appInsights.trackTrace({ 'message': message, 'properties': properties, 'severityLevel': appInsights.SeverityLevel.Error });
		}

		const addChatEvents = function() {
			Array.prototype.forEach.call(document.querySelectorAll(".initChat"), function(element) {
				if (openChat) {
					element.addEventListener('click', function(e) {
						e.preventDefault();
						element.classList.add("chat-active");
						openChat.getAttribute('aria-expanded') === 'false' && showChat(e);
					});
				} else {
					//if there is a button class it will override hide class
					element.style.display = "none";
				}
			});
		}
		
		return {
			Init: init
		}
	};

	contactPanel().Init();
})();