var tabs4i = function(){
	let tabLinks = [];
	let tabContent;
	
	const init = (element) =>{
		const allTabs = element.getElementsByClassName("tabLink");
		tabContent = element.getElementsByClassName("tabContent");
		
		for(let i = 0; i < allTabs.length; i++){
			const isVisible = findVisible(allTabs[i]);
			if(isVisible) tabLinks.push(allTabs[i]);
		}
		
		for(let i = 0; i < tabLinks.length; i++){
	    		tabLinks[i].addEventListener("click", openTab);
	  	}
	  	
		tabKeys();
	};
	
	const findVisible = (el) =>{
		return el.offsetWidth > 0 || el.offsetHeight > 0;
	};
	
	const openTab = (e) =>{
		e.preventDefault();
		const el = e.currentTarget;
		resetTabs();
            	initTab(el);
	};
	
	const resetTabs = () =>{
		for(let i = 0; i < tabLinks.length; i++){
	    		tabLinks[i].classList.remove("active");
                	tabLinks[i].setAttribute('aria-selected', 'false');
                	tabLinks[i].setAttribute('tabindex', '-1');
	  	}
	  	
	  	for(let i = 0; i < tabContent.length; i++){
	    		tabContent[i].classList.add('hide');
                	tabContent[i].setAttribute('aria-hidden', 'true');
                	tabContent[i].removeAttribute("tabindex");
	  	}
	};
	
	const initTab = (tabLink) =>{ // select/show specific tab
		if(tabLink){
			let targetContent = "";
			if(tabLink.getAttribute("data-content") !== null) targetContent = tabLink.getAttribute("data-content");
			else targetContent = tabLink.getAttribute("aria-controls");

			tabLink.classList.add("active");
			tabLink.setAttribute("aria-selected", "true");
			tabLink.setAttribute("tabindex", "0");
			
			const contentToShow = document.getElementById(targetContent);
			contentToShow.classList.remove("hide");		
			contentToShow.setAttribute("aria-hidden", "false");
			contentToShow.setAttribute("tabindex", "0");
		}
	};
	
	const tabKeys = () =>{
		const firstTab = tabLinks[0];
		const lastTab = tabLinks[tabLinks.length-1];	
		for(let i = 0; i < tabLinks.length; i++){
			const nextTab = tabLinks[i + 1];
			const prevTab = tabLinks[i - 1];
			
			tabLinks[i].addEventListener("keydown", function (e) {
				// Listen for the up, down, left and right arrow keys, otherwise, end here
				if ([37, 38, 39, 40].indexOf(e.which) == -1) { return; }
	            		if (document.activeElement == lastTab) {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(prevTab){
			                    			prevTab.focus(); 
			                        		prevTab.click();
			                        	}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(firstTab){
		                        			firstTab.focus();
		                        			firstTab.click();
		                        		}
		                    }
	            		}else if (document.activeElement == firstTab) {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(lastTab){
		                        			lastTab.focus();
		                        			lastTab.click(); //focus on last and click
		                        		}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(nextTab){
		                        			nextTab.focus();
			                        		nextTab.click();
			                        	}
		            		}
	            		}else {
	            			switch (e.which) {
			                	case 37: // left arrow
			                	case 38: // up arrow
			                    		e.preventDefault();
			                    		e.stopPropagation();
			                    		if(prevTab){
			                        		prevTab.focus();
			                        		prevTab.click();
			                        	}
			                    		break;
			                	case 39: // right arrow
			               	case 40: // down arrow
			                    		e.preventDefault();
			                   	 	e.stopPropagation();
			                   	 	if(nextTab){
			                    			nextTab.focus();
			                        		nextTab.click();
			                        	}
		            		}
	            		}
			});
		}
	};
	
	return {
		init: init,
		initTab: initTab
	};
};